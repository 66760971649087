import { Rest, API } from 'src/api';
import { BaseService } from './base-service';

interface BaseDownload {
  type: string;
  apps: string[];
  fileName: string;
}

export interface DownloadPayload extends BaseDownload {
  parameters: {
    unified_interaction_id: string;
    start_date: string;
    end_date: string;
  };
}

export interface Download extends BaseDownload {
  id: string;
  date_requested: string;
  status: string;
  user_id: string;
  date_completed?: string;
  date_expires?: string;
  date_download?: string;
  filesize?: 123;
  parameters?: {
    unified_interaction_id: string;
    start_date: string;
    end_date: string;
  };
  download_url?: string;
}

export class DownloadService extends BaseService {
  static async getDownloads(userId: string): Promise<Download[]> {
    return Rest.httpGet(API.DOWNLOADS.GET_DOWNLOADS(userId), {
      errorTitle: '[API] Error GET downloads',
    }).promise;
  }

  static async createDownload(userId: string, payload: DownloadPayload): Promise<Download> {
    return Rest.httpPost(API.DOWNLOADS.CREATE(userId), {
      body: payload,
      errorTitle: '[API] Error POST create download',
    }).promise;
  }

  static async getDownloadUrl(userId: string, downloadId: string): Promise<{ url: string }> {
    return Rest.httpGet(API.DOWNLOADS.GET_DOWNLOAD_URL(userId, downloadId), {
      errorTitle: '[API] Error GET download url',
    }).promise;
  }
}
