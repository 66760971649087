import React, { PureComponent, createRef } from 'react';
import Mousetrap from 'mousetrap';
import classNames from 'classnames';

import { DateLastPeriod, daysAgo, TIME_PRESETS, Time, TimePreset } from 'src/utils/time';
import analytics from 'src/data/analytics';
import { bemPrefix } from 'src/utils';

import DateRangePicker from '../molecules/date_range_picker';
import { Button, Dialog, Icon } from '../molecules';

import './date-range-selector-picker.scss';

export interface DateRangeSelectorPickerProps {
  startDate: number;
  endDate: number;
  analyticsEventKey?: string;
  hasHintInfo?: boolean;
  allTimeOption?: TimePreset;
  onUpdateDateRange(start: number, end: number, name: DateLastPeriod | 'custom'): void;
}

const bem = bemPrefix('date-range-selector-picker');

export class DateRangeSelectorPicker extends PureComponent<DateRangeSelectorPickerProps> {
  mousetrap = new Mousetrap();

  state = {
    isOpen: false,
  };

  datesRef = createRef<HTMLDivElement>();

  static defaultProps = {
    endDate: new Date().valueOf(),
    startDate: daysAgo(30).valueOf(),
    analyticsEventKey: 'Basic Data',
  };

  toggleDateRange = () => this.setState({ isOpen: !this.state.isOpen });

  onSubmit = (start: number, end: number, name: DateLastPeriod) => {
    this.props.onUpdateDateRange(start, end, name);
    this.toggleDateRange();
    analytics.trackEvent(
      this.props.analyticsEventKey,
      'Date Range Change',
      `${Time.format(start, Time.FORMAT.YYYY_MM_DD)}|${Time.format(end, Time.FORMAT.YYYY_MM_DD)}`,
    );
  };

  render() {
    const { allTimeOption, ...rest } = this.props;
    const presets = allTimeOption ? [...TIME_PRESETS, allTimeOption] : TIME_PRESETS;

    return (
      <div className={bem()}>
        <div className={bem('container')} onClick={this.toggleDateRange} ref={this.datesRef}>
          <Button className={bem('container-button')} normalize>
            {Time.format(this.props.startDate)} - {Time.format(this.props.endDate)}
          </Button>
          <Icon
            name="arrowDown"
            className={classNames(bem('container-icon'), { reversedIcon: this.state.isOpen })}
            onClick={this.toggleDateRange}
          />
        </div>
        {this.state.isOpen && (
          <Dialog
            className={bem('picker-dialog')}
            isOpen={this.state.isOpen}
            targetEl={this.datesRef.current}
            onClose={this.toggleDateRange}
            withArrow={false}
          >
            <DateRangePicker
              {...rest}
              className={bem('picker')}
              onSubmit={this.onSubmit}
              close={this.toggleDateRange}
              fieldName="dashboard-date-range"
              presets={presets.map((item) => ({
                ...item,
                id: item.label.toLowerCase().replace(/ /g, '_'),
              }))}
            />
          </Dialog>
        )}
      </div>
    );
  }
}
