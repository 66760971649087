import { AppEventList } from 'src/reducers/events';
import { OrganizationApp } from 'src/reducers/organizations';
import { Platform, TargetingCriteria } from 'src/types/core';
import gguid from 'src/utils/gguid';
import { CriteriaSubRule } from '../../segment-builder/types';
import {
  CriteriaParentRule,
  CriteriaRuleGroup,
  CriteriaRuleType,
  CriteriaSegment,
  CriteriaUnifiedAttributes,
  CriteriaUnifiedTargeting,
} from '../types';
import { COUNT_IN_INSTALLED_VERSION_SUFFIXES, isAppCompatibleWithRule } from './app-compatibility';
import { convertToCriteria, injestCriteria } from './parse';

export const transformCriteriaForApp = ({
  app,
  criteria,
  multipleEvents,
  unifiedTargeting,
  unifiedAttributes,
}: {
  app: OrganizationApp;
  criteria: TargetingCriteria;
  multipleEvents: AppEventList[];
  unifiedTargeting: CriteriaUnifiedTargeting;
  unifiedAttributes: CriteriaUnifiedAttributes;
}): TargetingCriteria => {
  let segments = injestCriteria({
    criteria,
    unifiedTargeting,
    unifiedAttributes,
  });

  if (segments.length !== 0) {
    segments = segments
      .map(({ rules, ...rest }): CriteriaSegment | null => {
        let incompatibleRule = false;
        const filteredRules = rules.map((rule) => {
          if (rule.group === CriteriaRuleGroup.APPLICATION && rule.key === 'platform') {
            const { comparator, value } = rule as CriteriaSubRule;

            if ((comparator === '$eq' && value !== app.platform) || (comparator === '$ne' && value === app.platform)) {
              incompatibleRule = true;
              return rule;
            }

            //
            return {
              id: gguid(),
              key: 'current_time',
              comparator: '$gte',
              type: 'datetime' as CriteriaRuleType,
              value: 0,
            };
          }

          if (rule.group === CriteriaRuleGroup.CODE_POINT || rule.group === CriteriaRuleGroup.INTERACTIONS) {
            const { details } = rule as CriteriaParentRule;

            return {
              ...rule,
              details: details.map((detail) => {
                const { suffix, ...rest } = detail;

                if (suffix && COUNT_IN_INSTALLED_VERSION_SUFFIXES.has(suffix)) {
                  if (app.platform === Platform.Web) {
                    incompatibleRule = true;
                    return detail;
                  }

                  return {
                    ...rest,
                    suffix: app.platform === Platform.Android ? 'invokes/version_code' : 'invokes/cf_bundle_version',
                  };
                }

                return detail;
              }),
            };
          }

          return rule;
        });

        if (incompatibleRule) return null;

        if (
          !rules.every(
            (rule) => isAppCompatibleWithRule({ app, rule, multipleEvents, unifiedTargeting, unifiedAttributes })[0],
          )
        ) {
          return null;
        }

        return { rules: filteredRules, ...rest };
      })
      .filter((segment): segment is CriteriaSegment => !!segment);

    if (segments.length === 0) {
      return { $or: [] };
    }
  }

  return convertToCriteria(segments);
};
