/* eslint-disable react-hooks/rules-of-hooks */

import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { LockerInfo, BannerInfo } from 'src/interactions/newinteractions/common';
import { InteractionSection } from 'src/interactions/newinteractions/creation';
import { FanSignalTemplateName } from 'src/reducers/surveys-multi-apps/survey-multi-apps-templates';
import { ImtPermisson } from 'src/reducers/imt/imt.model';
import { useSurveyMultiAppsContext } from './survey-multi-apps-context';

export interface SurveyLockedBannerProps {
  section: InteractionSection;
}

export const SurveyLockedBanner: React.FC<SurveyLockedBannerProps> = ({ section }) => {
  const { hasResponses, isTriggeredBy, userPermisson, surveyId } = useSurveyMultiAppsContext();
  const location = useLocation();

  const fromSpecialTemplate = useMemo(() => {
    const name = new URLSearchParams(location.search).get('template');
    return {
      isLostFan: name === FanSignalTemplateName.LostFan,
    };
  }, [location.search]);

  const isContentTab = section === InteractionSection.Content;
  const isTargetingTab = section === InteractionSection.Targeting;
  const hasLimitedAccess = userPermisson === ImtPermisson.HAS_ACCESS_TO_SOME_APPS && surveyId;

  if (fromSpecialTemplate.isLostFan) {
    return (
      <BannerInfo
        text="
          This survey is created with predefined example content and Shifted to Risk rule in targeting.
          Both content and targeting can be changed to your needs.
        "
      />
    );
  }

  if (isTargetingTab && isTriggeredBy) {
    return (
      <LockerInfo
        className="survey-locked-banner"
        text="To change the targeting method, first unlink this survey from all other interactions."
      />
    );
  }

  if ((isContentTab || isTargetingTab) && hasLimitedAccess) {
    return (
      <LockerInfo
        className="survey-locked-banner"
        text="Editing the survey is restricted because of permissons control."
      />
    );
  }

  if (hasResponses && !isTargetingTab) {
    return (
      <LockerInfo
        className="survey-locked-banner"
        text="Editing the survey is restricted because responses have been collected."
      />
    );
  }

  return null;
};
