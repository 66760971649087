import { createAsyncThunk } from '@reduxjs/toolkit';
import { MongoInteractionService } from 'src/services';

export enum MessageCenterIdsActions {
  FETCH_MESSAGE_CENTER_IDS_BY_APPS = 'FETCH_MESSAGE_CENTER_IDS_BY_APPS',
}

export const fetchMessageCenterIdsByApps = createAsyncThunk(
  MessageCenterIdsActions.FETCH_MESSAGE_CENTER_IDS_BY_APPS,
  async ({ appIds }: { appIds: string[] }) => MongoInteractionService.getMessageCenterIdsByApps(appIds),
);
