import React, { useRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { bemPrefix } from 'src/utils';
import { InputText, InputTextProps } from '../input';
import { IconName, Icon } from '../icons';
import './search-input.scss';

export interface SearchRef {
  getValue(): string;
}

export interface SearchInputProps extends Pick<InputTextProps, 'placeholder'> {
  className?: string;
  iconName?: IconName;
  submitSearch?: boolean;
  text?: string;
  pillText?: string;
  onSearch?(text: string): void;
  onPillClick?(): void;
  searchRef?: React.RefObject<SearchRef>;
  onChange?: InputTextProps['onChange'];
  onBlur?: InputTextProps['onBlur'];
}

const bem = bemPrefix('search-input');

export const SearchInput: React.FC<SearchInputProps> = ({
  className = '',
  iconName = 'magnifying',
  submitSearch = true,
  text,
  placeholder,
  pillText,
  onChange,
  searchRef,
  onBlur,
  onSearch = () => null,
  onPillClick = () => null,
}) => {
  const inputEl = useRef<HTMLInputElement | null>(null);

  const onSubmit = useCallback(() => {
    if (inputEl.current) {
      const value = inputEl.current.value.trim();
      onSearch(value);
    }
  }, [inputEl.current, onSearch]);

  const onEnter = useCallback(
    (_e: React.KeyboardEvent) => {
      if (submitSearch) {
        onSubmit();
      }
    },
    [submitSearch, onSubmit],
  );

  useImperativeHandle(
    searchRef,
    () => ({
      getValue: () => (inputEl.current ? inputEl.current.value : ''),
    }),
    [searchRef, inputEl.current],
  );

  useEffect(() => {
    if (text !== undefined && inputEl.current) {
      inputEl.current.value = text;
    }
  }, [inputEl.current && inputEl.current.value, text]);

  const onClick = useCallback(() => {
    if (pillText && inputEl.current) {
      inputEl.current.value = '';
      onPillClick();
      inputEl.current.focus();
    }
  }, [inputEl.current && inputEl.current.value, pillText, onPillClick]);

  return (
    <div className={`${bem()} ${className}`} onClick={onClick}>
      <InputText
        inputClassName={bem('input')}
        type="search"
        placeholder={!pillText ? placeholder : undefined}
        onSubmit={onSubmit}
        onChange={onChange}
        onBlur={onBlur}
        onEnter={onEnter}
        innerRef={inputEl}
        preventDefault
      />
      <Icon className={bem('icon')} name={iconName} />
      {pillText && (
        <div className={bem('pill')}>
          <span className={bem('pill-text')}>{pillText}</span>
          <Icon name="closeBold" isInline />
        </div>
      )}
    </div>
  );
};

SearchInput.displayName = 'SearchInput';
