import React, { useCallback, useRef, useState } from 'react';
import Select, { components, Props, InputActionMeta, InputAction } from 'react-select';
import Creatable from 'react-select/creatable';
import AsyncSelect, { AsyncProps } from 'react-select/async';

import { useOnOutsideClick } from 'src/hooks/useOnOutsideClick';
import { useDropdownProps } from 'src/hooks';
import { bemPrefix, useFeatureSettings } from 'src/utils';
import { SearchInput } from 'src/components/molecules';
import { InputLabel, useFieldBlurHighlight } from 'src/components/molecules/input';
import { KeyboardKey } from 'src/components/molecules/util-handlers';
import { AppEventList, SimpleEventItem } from 'src/reducers/events';
import { InteractionType } from 'src/types/core';
import { OrganizationApp } from 'src/reducers/organizations';
import { doesInteractionSupportMultipleTargets } from 'src/interactions/newinteractions/interaction-utils';
import { EventTableOption } from './table-option';

import './searchable-select.scss';

export type ExtendedOrganizationApp = OrganizationApp & {
  hasArchivedEvent?: boolean;
};
export interface SearchableSelectOption extends Record<string, any> {
  id: string;
  label?: string;
  title?: string;
  value?: string;
  name?: string;
  isDisabled?: boolean;
  apps?: ExtendedOrganizationApp[];
}

export type SelectableRef = Select;
export type AsyncSelectableRef = any & {
  select: Select;
};

export interface SearchableSelectProps
  extends Pick<
    Props<SearchableSelectOption, any>,
    | 'value'
    | 'inputValue'
    | 'defaultInputValue'
    | 'defaultValue'
    | 'getOptionValue'
    | 'isOptionDisabled'
    | 'isOptionSelected'
    | 'onInputChange'
    | 'controlShouldRenderValue'
  > {
  selectRef?: React.RefObject<SelectableRef>;
  asyncSelectRef?: React.RefObject<AsyncSelectableRef>;
  className?: string;
  placeholder?: string;
  label?: string;
  createable?: boolean;
  closeMenuOnSelect?: boolean;
  disabled?: boolean;
  required?: boolean;
  options: SearchableSelectOption[];
  asyncDefaultOptions?: AsyncProps<SearchableSelectOption, false, any>['defaultOptions'];
  multipleTagretSelect?: boolean;
  apps?: OrganizationApp[];
  multipleEvents?: AppEventList[];
  eventsTitleList?: SimpleEventItem[];
  type: InteractionType;
  filterOption?: Props<SearchableSelectOption, any>['filterOption'];
  noOptionsMessage?: Props<SearchableSelectOption, any>['noOptionsMessage'];
  getOptionLabel?(option: SearchableSelectOption): React.ReactNode;
  getOptionLabelElement?(option: SearchableSelectOption): React.ReactNode;
  onSelect(option: SearchableSelectOption): void;
  onMultipleSelect(multipleEvent: SimpleEventItem): void;
  onInputEnter?(value: string | undefined): void;
  onInputBlur?(): void;
  onFocus?: Props<SearchableSelectOption, any>['onFocus'];
  onLoadOptions?: AsyncProps<SearchableSelectOption, false, any>['loadOptions'];
  isMultipleOptionDisabled?(multipleEvent: SimpleEventItem): boolean;
}

const bem = bemPrefix('searchable-select');

const noop = () => null;
const loadingMessage = () => 'Searching...';

const InputAction: Record<'change' | 'blur' | 'set' | 'close', InputAction> = {
  change: 'input-change',
  blur: 'input-blur',
  set: 'set-value',
  close: 'menu-close',
};

const Option = (props: any) => {
  return (
    <div title={props?.data?.title}>
      <components.Option {...props} />
    </div>
  );
};

export const SearchableSelect: React.FC<SearchableSelectProps> & {
  InputAction: typeof InputAction;
  selector: string;
} = function SearchableSelect({
  className = '',
  label,
  selectRef,
  asyncSelectRef,
  placeholder,
  options,
  disabled,
  createable,
  value,
  defaultValue,
  inputValue,
  required,
  defaultInputValue,
  asyncDefaultOptions = true,
  controlShouldRenderValue = false,
  closeMenuOnSelect,
  multipleTagretSelect,
  apps,
  multipleEvents,
  eventsTitleList,
  type,
  filterOption,
  getOptionLabel,
  getOptionLabelElement,
  getOptionValue,
  isOptionDisabled,
  isOptionSelected,
  onLoadOptions,
  onInputChange = noop,
  onInputBlur = noop,
  onInputEnter = noop,
  onSelect,
  onMultipleSelect,
  onFocus,
  noOptionsMessage,
  isMultipleOptionDisabled,
}) {
  const { focused, onBlurEvent } = useFieldBlurHighlight();
  const onSelectItem = useCallback((selected: SearchableSelectOption) => onSelect(selected), [onSelect]);

  const [searchValue, setSearchValue] = useState('');

  const { isOpenDropdown, closeDropdown, toggleDropdown } = useDropdownProps();

  const inputRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useOnOutsideClick([inputRef, dropDownRef], closeDropdown);

  const onMultipleSelectItem = useCallback(
    (selected: SimpleEventItem) => {
      onMultipleSelect(selected);
      setSearchValue('');
      closeDropdown();
    },
    [onMultipleSelect],
  );

  const handleSearchInputChange = useCallback(
    (value: string) => {
      setSearchValue(value);

      if (value && !isOpenDropdown) {
        toggleDropdown();
      }
    },
    [isOpenDropdown, toggleDropdown, setSearchValue],
  );

  const onToggleClick = useCallback(() => {
    if (disabled) return;
    toggleDropdown();
  }, [disabled, toggleDropdown]);

  const { flag, hasFeature } = useFeatureSettings();

  const isMultiAppInteraction =
    hasFeature(flag.INTERACTIONS_MULTITARGET) && doesInteractionSupportMultipleTargets(type);

  const onChangeInput = useCallback(
    (newValue: string, meta: InputActionMeta) => {
      if (meta.action === 'input-change') {
        onInputChange(newValue, meta);
      }
      if (meta.action === 'input-blur') {
        onInputBlur();
      }
    },
    [onInputChange, onInputBlur],
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === KeyboardKey.ENTER) {
        event.stopPropagation();
        event.preventDefault();
        onInputEnter(inputValue);
      }
    },
    [inputValue, onInputEnter],
  );

  const handeBlur = useCallback(() => {
    if (dropDownRef.current) {
      return;
    }
    setSearchValue('');
  }, [setSearchValue, dropDownRef]);

  const bemMods = {
    invalid: !!required && !(inputValue || value),
    focused,
  };

  const selectProps = {
    className: `react-select ${bem('', bemMods)} ${className}`,
    classNamePrefix: 'react-select',
    placeholder,
    options,
    isDisabled: disabled,
    value,
    defaultValue,
    inputValue,
    defaultInputValue,
    filterOption,
    formatOptionLabel: getOptionLabelElement || getOptionLabel,
    getOptionValue,
    isOptionDisabled,
    isOptionSelected,
    onInputChange: onChangeInput,
    onChange: onSelectItem,
    onBlur: onBlurEvent,
    controlShouldRenderValue,
    closeMenuOnSelect,
    onFocus,
    noOptionsMessage,
  };

  if (createable) {
    return (
      <>
        {label && <InputLabel label={label} />}
        <Creatable components={{ Option }} {...selectProps} />
      </>
    );
  }

  return (
    <>
      {label && <InputLabel label={label} />}
      {isMultiAppInteraction && multipleTagretSelect ? (
        <>
          <div ref={inputRef} onClick={onToggleClick}>
            <SearchInput
              className={bem('search-input')}
              text={searchValue}
              onChange={handleSearchInputChange}
              onSearch={noop}
              submitSearch={false}
              placeholder={placeholder}
              onBlur={handeBlur}
            />
          </div>
          {isOpenDropdown && (
            <EventTableOption
              tableOptionRef={dropDownRef}
              apps={apps}
              multipleEvents={multipleEvents}
              searchQuery={searchValue}
              onMultipleSelectItem={onMultipleSelectItem}
              isMultipleOptionDisabled={isMultipleOptionDisabled}
              eventsTitleList={eventsTitleList}
              isOpen={isOpenDropdown}
              type={type}
            />
          )}
        </>
      ) : (
        <>
          {onLoadOptions ? (
            <AsyncSelect
              {...selectProps}
              cacheOptions
              ref={asyncSelectRef}
              loadOptions={onLoadOptions}
              loadingMessage={loadingMessage}
              defaultOptions={asyncDefaultOptions}
              blurInputOnSelect={false}
              onKeyDown={onKeyDown}
              escapeClearsValue
            />
          ) : (
            <Select components={{ Option }} {...(selectProps as any)} ref={selectRef} />
          )}
        </>
      )}
    </>
  );
};

SearchableSelect.displayName = 'SearchableSelect';
SearchableSelect.InputAction = InputAction;
SearchableSelect.selector = `.react-select.${bem()}`;
