import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'ramda';
import { SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.model';
import { bemPrefix } from 'src/utils';
import {
  AppInteractionData,
  InteractionType,
  Platform,
  PromptActionType,
  SdkVersion,
  SelectedApps,
} from 'src/types/core';
import { InputCheckbox, Button, DropDown, Icon, HoverTooltip } from 'src/components/molecules';
import { sortBy, SortOrder } from 'src/utils/sort';
import { OrganizationApp } from 'src/reducers/organizations';
import { InteractionSection } from 'src/interactions/newinteractions/creation/shared-types';
import { PopoverPlacement } from 'src/components/molecules/popover/simple-popover';
import { ImtModel, ImtPermisson, ImtUserRole } from 'src/reducers/imt/imt.model';
import { ALL_PLATFORMS_OPTION, getAppsToSelect, getExtendedOrgApp, getSelectedApps } from './imt-apps-utils';
import { SurveyLockedBanner } from '../survey-multi-apps-form/survey-multi-apps-locked-banner';

import './imt-apps.scss';

const bem = bemPrefix('multi-apps');

interface ImtAppsProps {
  appId: string;
  type: InteractionType;
  platform: Platform;
  interactionData: AppInteractionData[];
  formRef?: React.RefObject<HTMLFormElement>;
  orgName: string;
  organizationApps: OrganizationApp[];
  interactionSDKVersion: SdkVersion | undefined;
  isNewInteraction: boolean;
  hasResponses?: boolean;
  userPermisson: ImtPermisson;
  userRole: ImtUserRole;
  selectApps: (selectedApps: SelectedApps[], allCodePoints?: string[]) => void;
  getIsUserHasFullAccessToApp: (appId: string) => boolean;
}

const selectedOptions = [
  { value: ALL_PLATFORMS_OPTION, label: ALL_PLATFORMS_OPTION },
  { value: Platform.iOS, label: 'iOS Apps' },
  { value: Platform.Android, label: 'Android Apps' },
  { value: Platform.Web, label: 'Websites' },
];

const columnTitles = [
  { title: 'App Name', key: 'title', sortable: true },
  { title: 'Type', key: 'platform', sortable: true },
  { title: 'Monthly Active Users', key: 'mau', sortable: true },
  { title: 'Latest SDK Version', key: 'latestSDK', sortable: false },
];

export const ImtApps: React.FC<ImtAppsProps> = ({
  appId = '',
  type,
  platform,
  interactionData = [],
  organizationApps,
  orgName,
  interactionSDKVersion,
  isNewInteraction = false,
  hasResponses = false,
  userPermisson,
  userRole,
  selectApps,
  getIsUserHasFullAccessToApp,
}) => {
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof OrganizationApp; direction: SortOrder }>({
    key: 'title',
    direction: SortOrder.asc,
  });

  const currentApp = {
    appId,
    platform,
  };

  const hasRemovedApps = useMemo(() => interactionData.some(({ isRemoved }) => isRemoved), [interactionData]);

  const allCodePoints = [...new Set(interactionData.flatMap((data) => data.codePoints || []))];

  const hasInitiatorAction =
    interactionData[0]?.actions?.some((action) => action.interactionType === PromptActionType.Initiator) ?? false;

  const hasPermissonToEditApps = isNewInteraction || ImtModel.getIsUserCanEditTargetedApps(userPermisson, userRole);

  const isDisabled = hasResponses || hasInitiatorAction || !hasPermissonToEditApps;

  useEffect(() => {
    const platforms = [Platform.Android, Platform.iOS, Platform.Web];
    let updatedSelectedPlatforms = [...selectedPlatforms];

    const isSelectAllAppsForOrg =
      organizationApps.length === interactionData.filter(({ isRemoved }) => !isRemoved).length;
    if (isSelectAllAppsForOrg && !updatedSelectedPlatforms.includes(ALL_PLATFORMS_OPTION)) {
      updatedSelectedPlatforms.push(ALL_PLATFORMS_OPTION);
    }
    if (!isSelectAllAppsForOrg && updatedSelectedPlatforms.includes(ALL_PLATFORMS_OPTION)) {
      updatedSelectedPlatforms = updatedSelectedPlatforms.filter((item) => item !== ALL_PLATFORMS_OPTION);
    }

    platforms.forEach((platform) => {
      const orgAppsByPlatform = organizationApps.filter((app) => app.platform === platform) || [];
      const interactionDataAppsByPlatform = interactionData.filter(
        (app) => app.platform === platform && !app.isRemoved,
      );

      const isPlatformSelected = updatedSelectedPlatforms.includes(platform);

      if (orgAppsByPlatform.length === interactionDataAppsByPlatform.length && !isPlatformSelected) {
        updatedSelectedPlatforms.push(platform);
      }
      if (orgAppsByPlatform.length !== interactionDataAppsByPlatform.length && isPlatformSelected) {
        updatedSelectedPlatforms = updatedSelectedPlatforms.filter((item) => item !== platform);
      }
    });

    setSelectedPlatforms(updatedSelectedPlatforms);
  }, [interactionData, organizationApps]);

  const dataApps = organizationApps.map((app) => getExtendedOrgApp(app, interactionSDKVersion));

  useEffect(() => {
    const isEmptyInteractionData = !interactionData.length || interactionData.some(({ appId }) => !appId);
    if (isEmptyInteractionData && isNewInteraction) {
      selectApps([currentApp]);
    }
  }, []);

  const onAppSelect = useCallback(
    (app: OrganizationApp) => selectApps(getSelectedApps(app, interactionData), allCodePoints),
    [interactionData, selectApps, allCodePoints],
  );

  const getIsAppLinkedToLD = (app: OrganizationApp) => {
    const { codePoints = [], triggeredBy = [] } = interactionData.find(({ appId }) => appId === app.id) || {};
    return SurveyMultiAppsModel.isFromLoveDialog(codePoints) && !isEmpty(triggeredBy);
  };

  const getIsAppCheckboxDisabled = (app: OrganizationApp) => {
    if (isDisabled || (isNewInteraction && app.id === appId) || !getIsUserHasFullAccessToApp(app.id)) {
      return true;
    }

    return getIsAppLinkedToLD(app);
  };

  const isRemovedAllExistingApps = interactionData.every(
    ({ isRemoved, isExistingInDB }) => isRemoved && isExistingInDB,
  );

  const isClearBtnDisabled =
    (isNewInteraction && interactionData.length === 1) ||
    interactionData.length === 0 ||
    isDisabled ||
    isRemovedAllExistingApps;

  const onSelectAll = useCallback(
    (value: string) => {
      let newSelectedPlatforms: string[] = selectedPlatforms;

      const isAllOptionSelected = selectedPlatforms.includes(ALL_PLATFORMS_OPTION);
      const isValueSelected = selectedPlatforms.includes(value);

      if (value === ALL_PLATFORMS_OPTION) {
        newSelectedPlatforms = isAllOptionSelected ? [] : [ALL_PLATFORMS_OPTION];
      }

      if (value !== ALL_PLATFORMS_OPTION && isValueSelected) {
        newSelectedPlatforms = newSelectedPlatforms.filter(
          (platform) => platform !== value && platform !== ALL_PLATFORMS_OPTION,
        );
      }

      if (value !== ALL_PLATFORMS_OPTION && !isValueSelected) {
        newSelectedPlatforms = newSelectedPlatforms
          .filter((platform) => platform !== ALL_PLATFORMS_OPTION)
          .concat(value);
      }

      setSelectedPlatforms(newSelectedPlatforms);

      const selectedAppsData: SelectedApps[] = getAppsToSelect(
        organizationApps,
        newSelectedPlatforms,
        interactionData,
        appId,
        isNewInteraction,
        getIsUserHasFullAccessToApp,
      ).map((app) => ({
        appId: app.id,
        platform: app.platform,
      }));

      selectApps(selectedAppsData, allCodePoints);
    },
    [organizationApps, appId, selectedPlatforms],
  );

  const onClearSelection = useCallback(() => {
    setSelectedPlatforms([]);
    selectApps(
      dataApps.filter(getIsAppCheckboxDisabled).map((app) => ({
        appId: app.id,
        platform: app.platform,
      })),
    );
  }, [isNewInteraction, currentApp]);

  const onSortClick = (key: keyof OrganizationApp) => {
    if (!columnTitles.find((column) => column.key === key)?.sortable) {
      return;
    }
    let direction: SortOrder = SortOrder.asc;
    if (sortConfig && sortConfig.key === key && sortConfig.direction === SortOrder.asc) {
      direction = SortOrder.desc;
    }
    setSortConfig({ key, direction });
  };

  const sortedApps = useMemo(() => {
    return sortBy(dataApps, sortConfig.key, sortConfig.direction);
  }, [dataApps, sortConfig]);

  const interactionTypeText = type === InteractionType.Survey ? 'survey' : 'prompt';

  const isWarningVersionDisclaimer = useMemo(() => dataApps.some((app) => app.isWarningVersion), [dataApps]);

  const isChecked = (id: string) => interactionData.some((data) => data.appId === id && !data.isRemoved);

  return (
    <div className={bem()}>
      <SurveyLockedBanner section={InteractionSection.Apps} />
      <h2 className={bem('title')}>{`${isNewInteraction ? 'Choose' : 'Manage'}`} Apps</h2>
      <p className={bem('description')}>
        Choose apps that will see the {interactionTypeText}. You&apos;ll be able to set the targeting conditions for all
        the apps later.
      </p>
      <div className={bem('header')}>
        <span className={bem('group-name')}>{orgName}</span>
        <HoverTooltip
          content={'Not available with Workflow Initiator added'}
          placement={PopoverPlacement.bottomStart}
          className={bem('tooltip', !hasInitiatorAction ? 'disabled' : '')}
        >
          <div className={bem('select-dropdown', isDisabled ? 'disabled' : '')}>
            <DropDown
              placeholder="Select apps..."
              options={selectedOptions}
              onSelect={onSelectAll}
              selectedValue={selectedPlatforms}
              disabled={isDisabled}
              customIcon="arrowDown"
              customCloseDropdown
            />
          </div>
        </HoverTooltip>
      </div>
      <table className={bem('table')}>
        <thead>
          <tr>
            {columnTitles.map((column) => (
              <th key={column.key}>
                <div
                  className={classNames(bem('table-title'), { [bem('table-title', 'sortable')]: column.sortable })}
                  onClick={() => onSortClick(column.key as keyof OrganizationApp)}
                >
                  {column.title}
                  {column.sortable && (
                    <Icon
                      name="arrowDown"
                      className={classNames(bem('sort-icon'), {
                        [bem('sort-icon', 'active-asc')]:
                          sortConfig && sortConfig.key === column.key && sortConfig.direction === SortOrder.asc,
                        [bem('sort-icon', 'active-desc')]:
                          sortConfig && sortConfig.key === column.key && sortConfig.direction === SortOrder.desc,
                      })}
                    />
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
      </table>
      <div className={bem('scrollable-table')}>
        <table>
          <tbody>
            {sortedApps.map((app) => (
              <tr key={app.id}>
                <td className={bem('select-apps')}>
                  <HoverTooltip
                    content={'Not available with Workflow Initiator added'}
                    placement={PopoverPlacement.bottomStart}
                    className={bem('tooltip', !hasInitiatorAction ? 'disabled' : '')}
                  >
                    <InputCheckbox
                      className={bem('input-checkbox', isDisabled ? 'disabled' : '')}
                      name="chooseApps"
                      onChange={() => onAppSelect(app)}
                      checked={isChecked(app.id)}
                      disabled={getIsAppCheckboxDisabled(app)}
                      hintText={getIsAppLinkedToLD(app) ? "This app's love dialog is connected to this survey" : ''}
                    />
                  </HoverTooltip>
                  <div className={bem('app-name')}>
                    <img className={bem('app-name', 'icon')} src={app.icon_url || app.iconUrl} alt={app.title} />
                    <span className={bem('app-name', 'title')}>{app.title}</span>
                  </div>
                </td>
                <td>{app.platform}</td>
                <td>{app.mau === -1 ? 'N/A' : app.mau}</td>
                <td className={bem(app.isWarningVersion ? 'warning' : '')}>
                  {app.platform === Platform.Web ? 'N/A' : app.latestSDKVersion || '--'}
                  {app.isWarningVersion && '*'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isWarningVersionDisclaimer && (
        <div className={bem('warning')}>
          <p className={bem('warning', 'text')}>
            *This {interactionTypeText} uses features only available for SDK version{' '}
            {interactionSDKVersion?.replace('v', '')} or greater. The {interactionTypeText} will not be visible for this
            application.
          </p>
        </div>
      )}
      <div className={bem('clear-selection', isClearBtnDisabled ? 'disabled' : '')}>
        <Button onClick={onClearSelection} disabled={isClearBtnDisabled}>
          Clear Selection
        </Button>
        {hasRemovedApps && (
          <p className={bem('notification')}>Some criteria which only apply to removed apps will also be removed</p>
        )}
      </div>
    </div>
  );
};

ImtApps.displayName = 'ImtApps';
